import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';
import { Container, Card, Button, Row, Col, Form, Modal, Tab, Nav } from 'react-bootstrap';
import { useNavigate } from "react-router";
import HomeHeader from "../Header/HomeHeader";
import Headerlogin from "../Header/HeaderloginLite";
import searchIcon from "../../../assets/images/frontPageImg/interface.png";
import HomeFooter from "../Footer/HomeFooter";
import { getPostJobsAction, applyJobsPostAction, getPostJobsListOtherUsersAction, getMyAppliedJobsAction } from "../../../action/Admin.action";
import { useDispatch } from 'react-redux';
import SubFooter from "../Footer/SubFooter";
import LoginWithOtp from "./LoginWithOtp";
import PostApplyDetailsModel from "./PostApplyDetailsModel";
import { checkFile } from "../../../admin/common/function";
import JobDetailsModel from "./JobDetailsModels";
// import { fetchData } from "pdfjs-dist";

const Job = ({ selected }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isDisabled, setDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const status = JSON.parse(localStorage.getItem("status"));
    const [showApplyModal, setShowApplyModal] = useState(false);
    const handleOpenApplyModal = () => setShowApplyModal(true);
    const handleCloseApplyModal = () => setShowApplyModal(false);
    const [allJobsData, setAllJobsData] = useState([]);
    const [myPostingData, setMyPostingData] = useState([]);
    const [appliedJobsData, setAppliedJobsData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [formErrors, setFormErrors] = useState({});
    const [formData, setFormData] = useState({
        // name: '',
        // jobPosition: '',
        desc: '',
        postJobsId: ''
    });
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedJob, setSelectedJob] = useState(null);
    const [selectedJobDetails, setSelectedJobDetails] = useState(null);
    const [isJobDetailsModalOpen, setIsJobDetailsModalOpen] = useState(false);

    const openJobDetailsModal = (jobDetail) => {
        setSelectedJobDetails(jobDetail);
        setIsJobDetailsModalOpen(true);
    };

    const closeJobDetailsModal = () => {
        setSelectedJobDetails(null);
        setIsJobDetailsModalOpen(false);
    };
    const toggleModal = () => setModalOpen(!modalOpen);

    const openModalWithJobData = (job) => {
        setSelectedJob(job);
        setModalOpen(true);
    };
    const [activeTab, setActiveTab] = useState("allJobs"); // State to manage active tab
    // const getData = async () => {
    //     let resp = await getPostJobsListOtherUsersAction();
    //     if (resp.code === 200) {
    //         setJobData(resp.data);
    //     }
    // }
    const handleApplyNow = (jobId) => {
        setFormData({ ...formData, postJobsId: jobId });
        setShowModal(true);
    };


    const fetchData = async () => {
        let resp;

        if (activeTab === "allJobs") {
            resp = await getPostJobsListOtherUsersAction();
            if (resp && resp.code === 200) {
                setAllJobsData(resp.data);
            }
        } else if (activeTab === "myPosting") {
            resp = await getPostJobsAction();
            if (resp && resp.code === 200) {
                setMyPostingData(resp.data);
            }
        } else if (activeTab === "appliedJobs") {
            resp = await getMyAppliedJobsAction();
            if (resp && resp.code === 200) {
                const jobsPostDataArray = resp.data.map(item => item.jobsPostData);
                setAppliedJobsData(jobsPostDataArray);
            }
        }
    };
    const handleSubmit = async (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
            setValidated(true);
        } else {
            event.preventDefault();
            event.stopPropagation();
            setValidated(false);
            setDisabled(true);

            const postData = new FormData();
            postData.append("desc", formData.desc);
            postData.append("postJobsId", formData.postJobsId);

            if (formData.resume) {
                postData.append("resume", formData.resume);
            }

            try {
                const response = await applyJobsPostAction(postData);

                if (response.code === 200) {
                    setSuccessMessage("Application submitted successfully!");
                    setErrorMessage('');

                    setFormData({
                        // name: '',
                        // jobPosition: '',
                        desc: '',
                        postJobsId: '',
                        resume: null
                    });
                    setTimeout(() => {
                        setSuccessMessage('');
                        setShowModal(false);
                    }, 2000);
                    await fetchData();
                } else {
                    setErrorMessage(response.msg);
                    setSuccessMessage('');
                }
            } catch (error) {
                setErrorMessage("Error submitting application. Please try again.");
                setSuccessMessage('');
                console.error("Error submitting application:", error);
            } finally {
                setDisabled(false);
            }
        }
    };


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const fileChangedHandler = (event, elename) => {
        event.preventDefault();
        let file = event.target.files[0];

        // Allow PDF and Excel formats only
        const allowedExts = ['pdf', 'xls', 'xlsx'];
        const fileExt = file.name.split('.').pop().toLowerCase();

        // Check file extension and size (in KB)
        let fileValid = checkFile({ event: event, exts: allowedExts, size: 1024, sizeType: "KB", name: elename });
        let formErrorsData = { ...formData };

        if (fileValid === true) {
            // Update formData with the file and its preview URL if necessary
            let formDataData = formData;
            formErrorsData[elename] = "";
            formDataData[elename] = file;
            setFormData({ ...formData, ...formDataData });
            setFormErrors({ ...formErrors, ...formErrorsData });
        } else {
            setFormErrors({ ...formErrors, ...fileValid });
        }
    };
    const handleCloseModal = () => {
        setShowModal(false);
        setSuccessMessage('');
        setErrorMessage('');
        setFormData({
            // name: '',
            // jobPosition: '',
            desc: '',
            postJobsId: '',
            resume: null
        });
    };
    // const fetchData = async () => {
    //     let resp;
    //     if (activeTab === "allJobs") {
    //         resp = await getPostJobsListOtherUsersAction();
    //     } else if (activeTab === "myPosting") {
    //         resp = await getPostJobsAction();
    //     } else if (activeTab === "appliedJobs") {
    //         resp = await getPostJobsListOtherUsersAction();
    //         if (resp && resp.code === 200) {
    //             const filteredJobs = resp.data.filter(job => job.loginUserApplyJobsStatus === true);
    //             setJobData(filteredJobs);
    //             return;
    //         }
    //     }

    //     if (resp && resp.code === 200) {
    //         setJobData(resp.data);
    //     }
    // };

    const handleTabSelect = (tab) => {
        setActiveTab(tab);
    };
    // useEffect(() => {
    //     const fetchData = async () => {
    //         let resp;

    //         if (activeTab === "allJobs") {
    //             resp = await getPostJobsListOtherUsersAction();
    //             if (resp && resp.code === 200) {
    //                 setAllJobsData(resp.data);
    //             }
    //         } else if (activeTab === "myPosting") {
    //             resp = await getPostJobsAction();
    //             if (resp && resp.code === 200) {
    //                 setMyPostingData(resp.data);
    //             }
    //         } else if (activeTab === "appliedJobs") {
    //             resp = await getMyAppliedJobsAction();
    //             if (resp && resp.code === 200) {
    //                 // Optionally filter jobs here, if needed
    //                 const jobsPostDataArray = resp.data.map(item => item.jobsPostData);
    //                 setAppliedJobsData(jobsPostDataArray);
    //             }
    //         }
    //     };

    //     fetchData();
    // }, [activeTab]);
    useEffect(() => {
        fetchData();
    }, [activeTab]);

    useEffect(() => {
        // fetchData();
        window.scrollTo(0, 0);
    }, []);
    return (
        <Fragment>
            {status === 1 ? (
                <Headerlogin />
            ) : (
                <HomeHeader />
            )}
            <section className="job-section">
                <Container className="search-bar" >
                    <Row style={{ marginTop: status === 1 ? '50px' : '150px', paddingBottom: '20px' }}>
                        <Col md={8} >
                            <Tab.Container activeKey={activeTab} onSelect={handleTabSelect}>
                                <Nav variant="pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="allJobs">All Jobs</Nav.Link>
                                    </Nav.Item>
                                    {status === 1 && (
                                        <>
                                            <Nav.Item>
                                                <Nav.Link eventKey="myPosting">My Posting</Nav.Link>
                                            </Nav.Item>
                                            <Nav.Item>
                                                <Nav.Link eventKey="appliedJobs">Applied Jobs</Nav.Link>
                                            </Nav.Item>
                                        </>
                                    )}
                                </Nav>
                            </Tab.Container>

                            {/* <Form style={{ paddingTop: '25px' }}> */}
                            {/* <Row className="g-2">

                                    <Col xs={12} md={4}>
                                        <Form.Group>

                                            <Form.Select
                                                name="subCate"

                                            >
                                                <option value="">Select</option>

                                            </Form.Select>
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid .
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Col> */}
                            {/* <Col xs={12} md={4}>
                                        <div className="d-flex search-baree" >
                                            <Form.Control

                                                type="text"
                                                name="name"
                                                placeholder="🔍 Search "
                                                className="custom-inputee custom-placeholder"

                                                autocomplete="off"
                                            />
                                            <Button className="search-button" type="submit">
                                                <img src={searchIcon} alt="Search" />
                                            </Button>
                                        </div> */}

                            {/* {suggestions.length > 0 && ( */}
                            {/* <ul className="suggestions-list"> */}
                            {/* {suggestions.map((category) => ( */}
                            {/* <li


                                                className="suggestion-item"
                                            >
                                                <span className="suggestion-icon">🔍</span>
                                                <div className="suggestion-details">
                                                    <span className="suggestion-name"></span>
                                                    <span className="suggestion-category">Category</span>
                                                </div>
                                            </li> */}
                            {/* ))} */}
                            {/* </ul> */}
                            {/* )} */}
                            {/* </Col> */}
                            {/* </Row> */}
                            {/* </Form> */}
                        </Col>
                        {/* <Col md={4} className="text-end" style={{ paddingTop: '25px' }} >
                            <button className="ms-2 custom-button" style={{ width: '105px', padding: '5px', borderRadius: '8px' }} onClick={() => navigate(-1)}>Back</button>
                        </Col> */}
                    </Row>
                </Container>
                <Container>
                    {activeTab === "allJobs" && (
                        <div className="card-grid mt-3">
                            {allJobsData.length > 0 ? (
                                allJobsData.map((item, index) => (
                                    <Card key={index} className="card-custom hand">
                                        <div className="job-rating-card">
                                            <div className="rating-badge">
                                                {/* Add your rating badge here if needed */}
                                            </div>
                                        </div>
                                        <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                            <div className="card-content flex-grow-1">
                                                <Card.Title>{item.jobName}</Card.Title>
                                                <Card.Subtitle className="mb-2">
                                                    <i className="fas fa-thin fa-film" style={{ color: "red", marginRight: '5px' }}></i>
                                                    {item.companyName}
                                                </Card.Subtitle>
                                                <Card.Text>
                                                    <i className="fas fa-map-marker-alt"></i>{item.location}
                                                    <br />
                                                    <span style={{ marginLeft: '10px', marginTop: '5px', display: 'inline-block' }}>
                                                        Desc: {item.desc}
                                                    </span>
                                                </Card.Text>
                                            </div>
                                            <div className="button-group mt-2">
                                                {item.loginUserApplyJobsStatus ? (
                                                    <button
                                                        className="btn"
                                                        disabled
                                                        style={{
                                                            backgroundColor: '#6c757d',
                                                            color: '#FFFFFF',
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        Applied
                                                    </button>
                                                ) : (
                                                    <button
                                                        className="btn"
                                                        onClick={() => {
                                                            if (status === 1) {
                                                                handleApplyNow(item._id);
                                                            } else {
                                                                // handleOpenApplyModal();
                                                                navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);

                                                            }
                                                        }}
                                                        style={{
                                                            backgroundColor: '#AA1163',
                                                            color: '#FFFFFF',
                                                            fontSize: '12px'
                                                        }}
                                                    >
                                                        Apply Now
                                                    </button>
                                                )}

                                                <button

                                                    className="btn"
                                                    onClick={() => openJobDetailsModal(item)}
                                                    style={{
                                                        backgroundColor: '#AA1163',
                                                        color: '#FFFFFF',
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                ))
                            ) : (
                                <div className="no-data">
                                    <p style={{ fontSize: '19px' }}>No jobs available at the moment.</p>
                                </div>
                            )}

                        </div>
                    )}

                    {activeTab === "myPosting" && (
                        <div className="card-grid mt-3">
                            {/* Render content for "myPosting" tab */}
                            {myPostingData.length > 0 ? (
                                myPostingData.map((item, index) => (
                                    <Card key={index} className="card-custom hand">
                                        <div className="job-rating-card">
                                            <div className="rating-badge">
                                                {/* Add your rating badge here if needed */}
                                            </div>
                                        </div>
                                        <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                            <div className="card-content flex-grow-1">
                                                <Card.Title>{item.jobName}</Card.Title>
                                                <Card.Subtitle className="mb-2">
                                                    <i className="fas fa-thin fa-film" style={{ color: "red", marginRight: '5px' }}></i>
                                                    {item.companyName}
                                                </Card.Subtitle>
                                                <Card.Text>
                                                    <i className="fas fa-map-marker-alt"></i>{item.location}
                                                    <br />
                                                    <span style={{ marginLeft: '10px', marginTop: '5px', display: 'inline-block' }}>
                                                        Desc: {item.desc}
                                                    </span>
                                                </Card.Text>
                                            </div>
                                            <div className="button-group mt-2">
                                                <button
                                                    className="btn"
                                                    onClick={() => {
                                                        if (status === 1) {
                                                            openModalWithJobData(item._id);
                                                        } else {
                                                            // handleOpenApplyModal();
                                                            navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);

                                                        }
                                                    }}
                                                    style={{
                                                        backgroundColor: '#AA1163',
                                                        color: '#FFFFFF',
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    See Applicants
                                                </button>
                                            </div>
                                        </Card.Body>
                                    </Card>

                                ))
                            ) : (
                                <div className="no-data">
                                    <p>No posts available.</p>
                                </div>
                            )}
                            <PostApplyDetailsModel isOpen={modalOpen} toggle={toggleModal} job={selectedJob} />
                        </div>
                    )}

                    {activeTab === "appliedJobs" && (
                        <div className="card-grid mt-3">
                            {/* Render content for "appliedJobs" tab */}
                            {appliedJobsData.length > 0 ? (
                                appliedJobsData.map((item, index) => (
                                    <Card key={index} className="card-custom hand">
                                        <div className="job-rating-card">
                                            <div className="rating-badge">
                                                {/* Add your rating badge here if needed */}
                                            </div>
                                        </div>
                                        <Card.Body className="d-flex flex-column" style={{ padding: '1px 10px 15px 10px' }}>
                                            <div className="card-content flex-grow-1">
                                                <Card.Title>{item.jobName}</Card.Title>
                                                <Card.Subtitle className="mb-2">
                                                    <i className="fas fa-thin fa-film" style={{ color: "red", marginRight: '5px' }}></i>
                                                    {item.companyName}
                                                </Card.Subtitle>
                                                <Card.Text>
                                                    <i className="fas fa-map-marker-alt"></i>{item.location}
                                                    <br />
                                                    <span style={{ marginLeft: '10px', marginTop: '5px', display: 'inline-block' }}>
                                                        Desc: {item.desc}
                                                    </span>
                                                </Card.Text>
                                            </div>
                                            <div className="button-group mt-2">

                                                <button
                                                    className="btn"
                                                    disabled
                                                    style={{
                                                        backgroundColor: '#6c757d',
                                                        color: '#FFFFFF',
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    Applied
                                                </button>
                                                {console.log('99999999', item)}
                                                <button

                                                    className="btn"
                                                    onClick={() => openJobDetailsModal(item)}
                                                    style={{
                                                        backgroundColor: '#AA1163',
                                                        color: '#FFFFFF',
                                                        fontSize: '12px'
                                                    }}
                                                >
                                                    View
                                                </button>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                ))
                            ) : (
                                <div className="no-data">
                                    <p>No applied jobs available.</p>
                                </div>
                            )}
                        </div>
                    )}
                    {isJobDetailsModalOpen && selectedJobDetails && (
                        <JobDetailsModel
                            isOpen={isJobDetailsModalOpen}
                            toggle={closeJobDetailsModal}
                            jobDetails={selectedJobDetails}
                        />
                    )}
                </Container>
            </section>
            {
                status === 1 ? (
                    <></>
                ) : (
                    <>
                        <HomeFooter />
                        <SubFooter />
                    </>
                )
            }
            <Modal show={showModal} backdrop="static" onHide={handleCloseModal}>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title>Apply for {formData.jobPosition}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='liteModal-content'>
                    <Form className="userForm" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Form.Group controlId="formResume" className="mt-3">
                            <Form.Label className="text-white mb-2">Upload Resume (PDF)</Form.Label>
                            <Form.Control
                                type="file"
                                name="resume"
                                onChange={(event) => fileChangedHandler(event, "resume")}
                                accept=".pdf,.xls,.xlsx"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please upload a valid resume in PDF or Excel format.
                            </Form.Control.Feedback>
                        </Form.Group>
                        {/* <Form.Group controlId="formName">
                            <Form.Label className="text-white mb-2">Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                value={formData.name}
                                onChange={handleInputChange}
                                placeholder="Enter your name"
                                autocomplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid  Name.
                            </Form.Control.Feedback>
                        </Form.Group> */}
                        {/* <Form.Group controlId="formJobPosition" className="mt-3">
                            <Form.Label className="text-white mb-2">Job Position</Form.Label>
                            <Form.Control
                                type="text"
                                name="jobPosition"
                                value={formData.jobPosition}
                                readOnly
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Job Position.
                            </Form.Control.Feedback>
                        </Form.Group> */}

                        <Form.Group controlId="formDesc" className="mt-3">
                            <Form.Label className="text-white mb-2">Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="desc"
                                value={formData.desc}
                                onChange={handleInputChange}
                                placeholder="Enter a description"
                                autocomplete="off"
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid Description.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {successMessage && <div className="alert alert-success mt-3">{successMessage}</div>}

                        {errorMessage && <div className="alert alert-danger mt-3">{errorMessage}</div>}


                        <div className="mt-4">
                            <Button variant="secondary" onClick={handleCloseModal}>
                                Close
                            </Button>{' '}
                            <Button variant="primary" type="submit" style={{ marginLeft: '5px' }} >
                                Submit Application
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
                {/* <Modal.Footer className='useModelHeader'> */}

                {/* </Modal.Footer> */}
            </Modal>
            {/* <Modal show={showApplyModal} onHide={handleCloseApplyModal} size="lg" centered>
                <Modal.Header className='useModelHeader' closeButton>
                    <Modal.Title className='text-white'>Login / Sign Up</Modal.Title>
                </Modal.Header>
                <Modal.Body className='loginModelBg'>
                    <LoginWithOtp />
                </Modal.Body>
            </Modal> */}
        </Fragment >
    );
};

export default Job;
