import React, { Fragment, useState, useEffect, useContext } from "react";
import { Container, Row } from "reactstrap";
import Header from "../Header/HeaderloginLite";
import "../../../assets/css/style.css";
import statusBar from "../../../assets/images/frontPageImg/statusBar.png";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, } from "react-bootstrap";
import CustomizerContext from "../../../_helper/Customizer";
import { emailOrPhoneOtpVerifyAction, logInWithEmailOrMobileAction } from "../../../action/Admin.action";
import man from "../../../assets/images/dashboard/profile.png";
import arrowIcon from "../../../assets/images/frontPageImg/arrowIcon.png";

// const OtpCheck = ({ state }) => {
//   const location = useLocation();
//   const { email, phone } = location.state || {};
//   const [formData, setFormData] = useState({ email, phone });
//   const navigate = useNavigate();
//   const { layoutURL } = useContext(CustomizerContext);
//   const [value, setValue] = useState(localStorage.getItem("profileURL" || man));
//   const [name, setName] = useState(localStorage.getItem("Name"));
//   useEffect(() => {

//     if (!email && !phone) {
//       navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);
//     }
//   }, [email, phone, navigate]);

//   const loginAuth = async (e) => {
//     e.preventDefault();
//     setValue(man);
//     setName("Emay Walter");

//     let resp = await emailOrPhoneOtpVerifyAction(formData);
//     if (resp.code === 200) {
//       localStorage.setItem("login", JSON.stringify(resp.data));
//       localStorage.setItem("loginData", JSON.stringify(true));
//       localStorage.setItem("userType", resp.data.userType);

//       localStorage.setItem("authorization", resp.data.token);
//       toast.success(resp.msg);

//       if (resp.data.status === 0) {
//         localStorage.setItem("loginType", "user");
//         window.location.href = `${process.env.PUBLIC_URL}/verified`;

//       } else {
//         localStorage.setItem("loginType", "user");
//         localStorage.setItem("status", JSON.stringify(resp.data.status));
//         window.location.href = `${process.env.PUBLIC_URL}/user/dashboard`;
//       }
//     } else {
//       var data = "";
//       if (resp.msg) {
//         data = resp.msg;
//       }
//       toast.error(resp.msg);
//     }
//   };

//   const handleChange = (name, event) => {
//     let from = { ...formData };

//     from[name] = event.target.value;

//     setFormData({ ...formData, ...from });
//   };
//   return (
//     <Fragment>
//       <Container fluid={true} className="p-0">
//         <Header />
//         <button
//           className="back-button"
//           onClick={() => navigate(-1)}
//           style={{
//             background: 'none',
//             border: 'none',
//             display: 'flex',
//             alignItems: 'center',
//             paddingTop: '30px',
//             marginLeft: '30px'
//           }}
//         >
//           <img src={arrowIcon} alt="Back" style={{ width: '15px', height: '15px', marginRight: '8px' }} />
//           <span className="text-white" style={{ marginLeft: '10px' }}>Back</span>
//         </button>
//         <Container>
//           <Row>
//             <div className="pt-4 pb-4">
//               <img src={statusBar} alt="" style={{
//                 maxWidth: "100%"
//               }} />
//             </div>
//           </Row>
//           <div className="pt-5">
//             <h1 className="text-white">One more step</h1>
//             <p className="text-white">
//               Please enter the code we sent to mailto:myemail@example.com
//             </p>
//           </div>
//           <div className="d-flex  align-items-center">
//             <Form className="userForm" onSubmit={loginAuth} style={{ width: "300px" }}>
//               <Form.Group>
//                 <Form.Label className="text-white">
//                   Code<span className="star">*</span>
//                 </Form.Label>
//                 <Form.Control
//                   type="text"
//                   placeholder="Enter 6-digit OTP"
//                   value={formData.otp || ''} onChange={(e) => { handleChange('otp', e) }}
//                   pattern="^[0-9]{6}$"
//                   minLength={6}
//                   maxLength={6}
//                   required
//                 />
//                 <Form.Control.Feedback type="invalid">
//                   please enter valid otp
//                 </Form.Control.Feedback>
//               </Form.Group>
//               <p className="text-white pt-2">
//                 Didn’t get the code?
//                 <span
//                   className="resend-text"
//                   style={{
//                     color: "#69B51D",
//                     cursor: "pointer",
//                     textDecoration: "underline",
//                   }}
//                 >
//                   Resend Code
//                 </span>
//               </p>
//               <div className=" mt-3">
//                 <button
//                   type="submit"
//                   style={{ backgroundColor: "#AA1163", width: 200 }}
//                   className="btn btn-square text-white"
//                 >
//                   Continue
//                 </button>
//               </div>
//             </Form>
//           </div>
//         </Container>
//       </Container>
//       <ToastContainer />
//     </Fragment>
//   );
// };


const OtpCheck = () => {
  const location = useLocation();
  const { email, phone } = location.state || {};
  const navigate = useNavigate();
  const { layoutURL } = useContext(CustomizerContext);

  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [formData, setFormData] = useState({ email, phone, otp: "" });
  const [isResendAvailable, setIsResendAvailable] = useState(false); // Resend availability
  const [timer, setTimer] = useState(30);

  useEffect(() => {
    if (!email && !phone) {
      navigate(`${process.env.PUBLIC_URL}/loginWithOtp`);
    }
    document.getElementById("otp-input-0").focus();

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setIsResendAvailable(true);
        }
        return prev - 1;
      });
    }, 1000);

    return () => clearInterval(countdown);
  }, [email, phone, navigate]);

  const handleChange = (e, index) => {
    const value = e.target.value;
    if (/^\d$/.test(value) || value === "") {
      let newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);


      if (value && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };


  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      let newOtp = [...otp];


      if (!newOtp[index] && index > 0) {
        document.getElementById(`otp-input-${index - 1}`).focus();
      }


      if (index >= 0) {
        newOtp[index] = "";
        setOtp(newOtp);
      }
    }
  };


  const loginAuth = async (e) => {
    e.preventDefault();

    let otpString = otp.join("");
    setFormData({ ...formData, otp: otpString });

    let resp = await emailOrPhoneOtpVerifyAction({ ...formData, otp: otpString });
    if (resp.code === 200) {
      localStorage.setItem("login", JSON.stringify(resp.data));
      localStorage.setItem("loginData", JSON.stringify(true));
      localStorage.setItem("userType", resp.data.userType);
      localStorage.setItem("authorization", resp.data.token);
      toast.success(resp.msg);

      if (resp.data.status === 0) {
        localStorage.setItem("loginType", "user");
        window.location.href = `${process.env.PUBLIC_URL}/verified`;

      } else {
        localStorage.setItem("loginType", "user");
        localStorage.setItem("status", JSON.stringify(resp.data.status));
        window.location.href = `${process.env.PUBLIC_URL}/user/dashboard`;
      }
    } else {
      toast.error(resp.msg || "Invalid OTP");
    }
  };

  const resendOtp = async () => {
    setIsResendAvailable(false);
    setTimer(30);

    let resp = await logInWithEmailOrMobileAction({ email, phone });
    if (resp.code === 200) {
      toast.success("OTP resent successfully");
    } else {
      toast.error(resp.msg || "Failed to resend OTP");
    }

    const countdown = setInterval(() => {
      setTimer((prev) => {
        if (prev === 1) {
          clearInterval(countdown);
          setIsResendAvailable(true);
        }
        return prev - 1;
      });
    }, 1000);
  };

  return (
    <Fragment>
      <Container fluid className="p-0">
        <Header />
        <button
          className="back-button"
          onClick={() => navigate(-1)}
          style={{
            background: 'none',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            paddingTop: '30px',
            marginLeft: '30px'
          }}
        >
          <img src={arrowIcon} alt="Back" style={{ width: '15px', height: '15px', marginRight: '8px' }} />
          <span className="text-white" style={{ marginLeft: '10px' }}>Back</span>
        </button>
        <Container>
          <Row>
            <div className="pt-4 pb-4">
              <img src={statusBar} alt="" style={{ maxWidth: "100%" }} />
            </div>
          </Row>
          <div className="pt-5">
            <h1 className="text-white">One more step</h1>
            <p className="text-white">
              Please enter the code we sent to {email || phone}
            </p>
          </div>

          <div className="d-flex align-items-center">
            <Form className="userForm" onSubmit={loginAuth} style={{ width: "300px", marginBottom: '20px' }}>
              <div className="otp-inputs d-flex justify-content-between">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    maxLength="1"
                    value={digit}
                    onChange={(e) => handleChange(e, index)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                    onFocus={(e) => e.target.select()}
                    className="otp-input"
                    style={{
                      width: "40px",
                      height: "40px",
                      textAlign: "center",
                      fontSize: "20px",
                      borderRadius: "4px",
                      marginRight: index < 5 ? "10px" : "0",
                      background: "linear-gradient(134.8deg, rgba(255, 255, 255, 0.1) 17.24%, rgba(255, 255, 255, 0.06) 95.25%)",
                      color: "#fff",
                      border: "1px solid darkgray"
                    }}
                  // placeholder="•"
                  />
                ))}
              </div>

              <p className="text-white pt-2">
                Didn’t get the code?{' '}
                <span
                  className={`resend-text ${!isResendAvailable ? 'disabled' : ''}`}
                  onClick={isResendAvailable ? resendOtp : null}
                  style={{
                    color: isResendAvailable ? "#69B51D" : "#AAAAAA",
                    cursor: isResendAvailable ? "pointer" : "not-allowed",
                    textDecoration: "underline",
                  }}
                >
                  Resend Code {timer > 0 && `(${timer})`}
                </span>
              </p>
              <div className="mt-3">
                <button
                  type="submit"
                  style={{ backgroundColor: "#AA1163", width: 200 }}
                  className="btn btn-square text-white"
                >
                  Continue
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </Container>
      <ToastContainer />
    </Fragment>
  );
};

export default OtpCheck;
